import React from "react";
import Img from "gatsby-image";
import * as styles from "./reviews-carousel.module.css";
import {Row, Col} from 'react-bootstrap';


const ReviewsCarousel = ({photo, name}) => {

  return (
    <div>
      <Row>
        <Col className={styles.imageContent}>
          <Img fluid={photo} />
          <p>{name}</p>
        </Col>
      </Row>
    </div>
  )
};

export default ReviewsCarousel


